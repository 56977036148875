import { useAppSelector } from '@/app'
import {
  FeatureNames,
  selectors as coreSelectors,
  defaultFlags,
} from '@/features/core'
import { useOpenFeatureClient } from '@/features/feature-flags/useOpenFeatureClient'

/**
 * Get the value of a feature flag.
 * If the feature is enabled in the central feature flag service, the value set in the core slice is returned.
 * If the feature is disabled in the central feature flag service, the default value is returned.
 * @param feature name of the settings/feature state
 * @returns the value of the setting/features state
 */
export const useFlag = (feature: FeatureNames) => {
  const flag = useAppSelector(coreSelectors.selectFlag(feature))
  const defaultValue = defaultFlags[feature]
  const client = useOpenFeatureClient()
  const featureEnabled = client.getBooleanValue(feature, false)
  return featureEnabled ? flag : defaultValue
}
