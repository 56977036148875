import {
  RouteBrowserState,
  initialCalendarState,
  initialState,
} from './routeBrowserSlice'

export const migrations = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  15: (_state: RouteBrowserState): RouteBrowserState => {
    return { calendar: initialCalendarState, state: initialState }
  },
} as const
