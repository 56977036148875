import { combineReducers, createSelector, createSlice } from '@reduxjs/toolkit'
import { CalendarState, createCalendarSlice } from '@/features/calendar'
import { defaultInitialState } from '@/features/calendar/calendarViewSlice'
import { RootState } from '@/app'
import { actions as shellActions } from '@/shell'
import { actions as resourceGroupActions } from '@/features/resource-groups'

export interface RouteBrowserSlice {
  isRouteSelctionOpen: boolean
  inFocus: boolean
  selectedResourceGroups: string[]
  useFilter: boolean
}

export const initialState: RouteBrowserSlice = {
  isRouteSelctionOpen: false,
  inFocus: false,
  selectedResourceGroups: [],
  useFilter: false,
}

export const initialCalendarState = {
  ...defaultInitialState,
  mode: 'editing',
} satisfies CalendarState

export const routeBrowserSlice = createSlice({
  name: 'routeBrowser',
  initialState,
  reducers: {
    toggleUseFilter: state => {
      state.useFilter = !state.useFilter
    },
    viewSelected: state => {
      state.isRouteSelctionOpen = false
    },
    toggleRouteSelection: state => {
      state.isRouteSelctionOpen = !state.isRouteSelctionOpen
    },
  },
  extraReducers: builder => {
    builder.addCase(shellActions.setFocus, (state, action) => {
      if (action.payload === 'routeBrowser') {
        if (state.inFocus) {
          state.isRouteSelctionOpen = !state.isRouteSelctionOpen
        } else {
          state.inFocus = true
        }
      } else {
        state.inFocus = false
      }
    })
    builder.addCase(resourceGroupActions.setResourceGroups, (state, action) => {
      if (state.inFocus) {
        state.selectedResourceGroups = action.payload
      }
    })
  },
})

export const routeBrowserCalendarSlice = createCalendarSlice({
  name: 'routeBrowserCalendar',
  initialState: initialCalendarState,
})

const reducer = combineReducers({
  calendar: routeBrowserCalendarSlice.reducer,
  state: routeBrowserSlice.reducer,
})

const selectRouteBrowser = (state: RootState) => state.routeBrowser
const selectCalendar = createSelector(
  selectRouteBrowser,
  state => state.calendar
)

const selectRouteBrowserState = createSelector(
  selectRouteBrowser,
  state => state.state
)

const selectSelectedResourceGroups = createSelector(
  selectRouteBrowserState,
  state => state.selectedResourceGroups
)

export const selectors = {
  selectRouteBrowser,
  selectCalendar,
  selectSelectedResourceGroups,
}

export const actions = {
  ...routeBrowserSlice.actions,
  ...routeBrowserCalendarSlice.actions,
}

export default reducer
export type RouteBrowserState = ReturnType<typeof reducer>
