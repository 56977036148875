import React, { useEffect, useState } from 'react'
import { Client, OpenFeature, Provider } from '@openfeature/web-sdk'
import { useAppDispatch, useAppSelector } from '@/app'
import { actions, selectors as coreSelectors } from '@/features/core'
import { OpenFeatureClientContext } from './useOpenFeatureClient'

type OpenFeatureClientProviderProps = {
  provider: Provider
  children: React.ReactNode
}

export const OpenFeatureClientProvider = ({
  provider,
  children,
}: OpenFeatureClientProviderProps) => {
  const dispatch = useAppDispatch()
  const { systemId } = useAppSelector(coreSelectors.selectBackend)
  const singleRouteMode = useAppSelector(
    coreSelectors.selectFlag('singleRouteMode')
  )

  const [prevProvider, setPrevProvider] = useState(provider)
  const [openFeatureClient, setOpenFeatureClient] = useState<Client>(
    OpenFeature.getClient()
  )
  const [state, setState] = useState<
    'initial' | 'initializing' | 'initialized'
  >('initial')

  useEffect(() => {
    const configureFeatureFlags = async () => {
      await OpenFeature.setProviderAndWait(provider.metadata.name, provider)
      await OpenFeature.setContext({
        systemId: systemId,
      })
      const client = OpenFeature.getClient(provider.metadata.name)
      if (!client.getBooleanValue('allowDebug', false)) {
        dispatch(actions.setDebug(false))
      }

      // TODO rename singleRouteMode to allowToggleSingleRouteMode
      if (
        !client.getBooleanValue('singleRouteMode', false) &&
        !singleRouteMode
      ) {
        dispatch(actions.setFlag({ feature: 'singleRouteMode', value: true }))
      }
      setOpenFeatureClient(client)
      setPrevProvider(provider)
      setState('initialized')
    }

    if (
      state === 'initial' ||
      (prevProvider !== provider && state === 'initialized')
    ) {
      setState('initializing')
      configureFeatureFlags()
    }
  }, [
    dispatch,
    openFeatureClient,
    prevProvider,
    provider,
    provider.metadata.name,
    singleRouteMode,
    state,
    systemId,
  ])

  return (
    <OpenFeatureClientContext.Provider value={openFeatureClient}>
      {children}
    </OpenFeatureClientContext.Provider>
  )
}
