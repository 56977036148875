import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AppThunk, RootState } from '@/app'
import dayjs from 'dayjs'
import { actions as resourceGroupsActions } from '@/features/resource-groups'

type Focus = 'planning' | 'routeBrowser' | 'singleRoute' | 'config' | 'splash'

export interface ShellState {
  focus: Focus
  previousFocus: Focus
  firstLaunchToday: boolean
  timers: {
    timeSinceLastLaunch: number
    lastLaunched: string
  }
}

const initialState: ShellState = {
  focus: 'planning',
  previousFocus: 'planning',
  firstLaunchToday: true,
  timers: {
    timeSinceLastLaunch: 0,
    lastLaunched: new Date(0).toISOString(),
  },
}

export const shellSlice = createSlice({
  name: 'shell',
  initialState,
  reducers: {
    setFocus: (state, action: PayloadAction<ShellState['focus']>) =>
      _setFocus(state, action.payload),
    appLaunched: {
      reducer: (state, action: PayloadAction<string>) =>
        _appLaunched(state, action.payload),
      prepare: () => ({ payload: new Date().toISOString() }),
    },
  },
})

const focusSelected =
  (newFocus: ShellState['focus']): AppThunk =>
  (dispatch, getState) => {
    dispatch(shellSlice.actions.setFocus(newFocus))
    if (newFocus === 'routeBrowser') {
      dispatch(
        resourceGroupsActions.setResourceGroups(
          getState().routeBrowser.state.selectedResourceGroups
        )
      )
    } else {
      dispatch(resourceGroupsActions.setResourceGroups([]))
    }
  }

export const actions = { ...shellSlice.actions, focusSelected }

const selectShell = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.shell
)
const selectFocus = createSelector(selectShell, state => state.focus)
const selectPreviousFocus = createSelector(
  selectShell,
  state => state.previousFocus
)
const selectFirstLaunchToday = createSelector(
  selectShell,
  state => state.firstLaunchToday
)
const selectTimers = createSelector(selectShell, state => state.timers)
const selectTimeSinceLastLaunch = createSelector(
  selectTimers,
  state => state.timeSinceLastLaunch
)
const selectCalendar = createSelector(
  (state: RootState) => state,
  state =>
    state.shell.focus === 'planning'
      ? state.planning
      : state.personalPlan.calendar
)

export const selectors = {
  ...shellSlice.selectors,
  selectShell,
  selectFocus,
  selectPreviousFocus,
  selectFirstLaunchToday,
  selectTimers,
  selectTimeSinceLastLaunch,
  selectCalendar,
}

export default shellSlice.reducer

// Reducer functions

function _setFocus(state: ShellState, newFocus: ShellState['focus']) {
  if (state.focus !== newFocus) {
    state.previousFocus = state.focus
    state.focus = newFocus
  }
}

function _appLaunched(state: ShellState, payload: string) {
  const now = dayjs(new Date())
  const timeSinceLastLaunch = now.diff(dayjs(state.timers.lastLaunched))
  // Dismiss rapid updates to allow working with StrictMode on during development
  if (timeSinceLastLaunch > 5000) {
    const lastLaunched = dayjs(state.timers.lastLaunched)
    state.timers.lastLaunched = payload
    state.timers.timeSinceLastLaunch = timeSinceLastLaunch
    state.firstLaunchToday = lastLaunched.isBefore(now, 'day')
  }
  if (state.firstLaunchToday) {
    // workaround for freeze-on-start bug
    if (state.focus !== 'splash') {
      _setFocus(state, 'splash')
    }
  }
}
