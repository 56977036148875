import { RootState } from '@/app'
import {
  CalendarContext,
  CalendarProps,
  type CalendarContextType,
} from './calendarContext'
import { createSelector } from '@reduxjs/toolkit'
import { PropsWithChildren, useMemo } from 'react'

export const CalendarProvider = ({
  calendarSelector,
  calendarActions,
  children,
}: PropsWithChildren<CalendarProps>) => {
  const selectSelectedVisit = createSelector(
    calendarSelector,
    p => p.selectedVisit
  )
  const selectIsSelected = useMemo(
    () =>
      createSelector(
        selectSelectedVisit,
        (_state: RootState, id: string) => id,
        (selectedVisit, id) => selectedVisit === id
      ),
    [selectSelectedVisit]
  )

  const selectSelectedGroup = useMemo(
    () => createSelector(calendarSelector, p => p.selectedGroup),
    [calendarSelector]
  )

  const selectDate = useMemo(
    () => createSelector(calendarSelector, p => p.date),
    [calendarSelector]
  )

  const selectScale = useMemo(
    () => createSelector(calendarSelector, p => p.scale),
    [calendarSelector]
  )
  const selectSelectedRoutes = useMemo(
    () => createSelector(calendarSelector, p => p.selectedRoutes),
    [calendarSelector]
  )
  const selectGutterInterval = useMemo(
    () => createSelector(calendarSelector, p => p.gutterInterval),
    [calendarSelector]
  )
  const selectLegacyGrouping = useMemo(
    () => createSelector(calendarSelector, p => p.legacyGrouping),
    [calendarSelector]
  )

  const selectIsVisitEditorOpen = useMemo(
    () => createSelector(calendarSelector, p => p.visitEditorOpen),
    [calendarSelector]
  )

  const selectMoveVisit = useMemo(
    () => createSelector(calendarSelector, p => p.moveVisit),
    [calendarSelector]
  )

  const selectMoveVisitForRoute = useMemo(
    () =>
      createSelector(
        selectMoveVisit,
        (_state: RootState, routeId: string) => routeId,
        (moveVisit, routeId) =>
          moveVisit.targetRoute === routeId || moveVisit.sourceRoute === routeId
            ? { ...moveVisit }
            : undefined
      ),
    [selectMoveVisit]
  )

  const ctx = {
    selectCalendar: calendarSelector,
    actions: calendarActions,
    selectors: {
      selectSelectedVisit,
      selectIsSelected,
      selectSelectedGroup,
      selectDate,
      selectScale,
      selectSelectedRoutes,
      selectGutterInterval,
      selectLegacyGrouping,
      selectIsVisitEditorOpen,
      selectMoveVisit,
      selectMoveVisitForRoute,
    },
  } satisfies CalendarContextType
  return (
    <CalendarContext.Provider value={ctx}>{children}</CalendarContext.Provider>
  )
}
