import { useAppDispatch, useAppSelector } from '@/app'
import {
  actions,
  selectors,
  useResourceGroupsModel,
} from '@/features/resource-groups'

export const useResourceGroups = () => {
  const { employeeResourceGroups, isLoading } = useResourceGroupsModel()

  const selectedResourceGroups = useAppSelector(
    selectors.selectSelectedResourceGroups
  )

  const dispatch = useAppDispatch()

  const { setResourceGroups } = actions

  return {
    isLoading,
    employeeResourceGroups,
    selectedResourceGroups,
    setSelectedResourceGroups: (selectedGroups: string[]) =>
      dispatch(setResourceGroups(selectedGroups)),
  }
}
