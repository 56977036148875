import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material'
import { useStyles } from './ServiceVisitEditor.style'
import { ServiceVisitEditorTitle } from './ServiceVisitEditorTitle'
import { ServiceVisitEditorContent } from './ServiceVisitEditorContent'
import { ServiceVisitEditorControls } from './ServiceVisitEditorControls'
import { ServiceVisitEditorProvider } from './ServiceVisitEditorProvider'
import { useServiceVisitEditor } from './ServiceVisitEditorContext'

export type ServiceVisitEditorProps = {
  visitId: string
  debug?: boolean
}

export const ServiceVisitEditor = (props: ServiceVisitEditorProps) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.component}>
      <ServiceVisitEditorProvider key={props.visitId} {...props}>
        <ServiceVisitEditorTitle />
        <ServiceVisitEditorContent />
        <ServiceVisitEditorControls />
      </ServiceVisitEditorProvider>
    </Box>
  )
}

export type ServiceVisitEditorDialogProps = ServiceVisitEditorProps & {
  open: boolean
  onClose?: () => void
}

export const ServiceVisitEditorDialog = ({
  open,
  visitId,
  debug,
  onClose,
}: ServiceVisitEditorDialogProps) => {
  const { classes } = useStyles()

  return (
    <ServiceVisitEditorProvider key={visitId} visitId={visitId} debug={debug}>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={onClose}
        PaperProps={{ className: classes.paper }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <ServiceVisitEditorTitle />
          {debug && <ShowRawResourcesToggle />}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ServiceVisitEditorContent />
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <ServiceVisitEditorControls onClose={onClose} />
        </DialogActions>
      </Dialog>
    </ServiceVisitEditorProvider>
  )
}

const ShowRawResourcesToggle = () => {
  const {
    serviceVisitData: { showDebug, setShowDebug },
  } = useServiceVisitEditor()
  return (
    <FormControlLabel
      control={
        <Switch checked={showDebug} onChange={() => setShowDebug(!showDebug)} />
      }
      label="Debug"
    />
  )
}
