import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import {
  DefaultOptions,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { compress, decompress } from 'lz-string'
import { PropsWithChildren } from 'react'
import { isUsingPersistentQueryCache } from './configuration'

export const ApplicationQueryClientProvider = ({
  queryClient,
  children,
  defaultQueryOptions,
}: PropsWithChildren<{
  queryClient: QueryClient
  defaultQueryOptions?: Partial<DefaultOptions<Error>>
}>) => {
  if (defaultQueryOptions) {
    queryClient.setDefaultOptions(defaultQueryOptions)
  }

  if (isUsingPersistentQueryCache) {
    console.info('Using persisted queries')
    const persister = createSyncStoragePersister({
      storage: window.localStorage,
      throttleTime: 10 * 1000,
      serialize: data => compress(JSON.stringify(data)),
      deserialize: data => JSON.parse(decompress(data) ?? data),
    })
    return (
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
    )
  } else
    return (
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    )
}
