import { createContext, useContext } from 'react'
import { useActualTime } from './actualTimeEditorHook'
import { useServiceVisitData } from '@/features/calendar/components/ServiceVisit/hooks'
import { VisitState } from '@/common-util/types'
import { MutationStatus } from '@tanstack/react-query'

type ServiceVisitEditorContent = {
  serviceVisitData: ReturnType<typeof useServiceVisitData>
  actualTimeData: ReturnType<typeof useActualTime>
  setState: (newState: VisitState, cancellationId?: string) => void
  mutationStatus: MutationStatus
  debug?: boolean
}
export const ServiceVisitEditorContext = createContext<
  ServiceVisitEditorContent | undefined
>(undefined)

export const useServiceVisitEditor = () => {
  const ctx = useContext(ServiceVisitEditorContext)
  if (ctx === undefined) {
    throw Error(
      'useServiceVisitEditor must be used within a ServiceVisitEditorProvider'
    )
  }
  return ctx
}
