import React from 'react'
import { Client } from '@openfeature/web-sdk'

export const OpenFeatureClientContext = React.createContext<Client | undefined>(
  undefined
)

export const useOpenFeatureClient = () => {
  const context = React.useContext(OpenFeatureClientContext)
  if (context === undefined) {
    throw Error('useClient must be used within a OpenFeatureClientProvider')
  }
  return context
}
