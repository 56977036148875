import { useQueryClient } from '@tanstack/react-query'
import { resourceKeys } from '@/api/hooks/keys'
import { useResources } from '@/api'
import { ResourceType } from '@alexandrainst/plana-react-api'

export const useKeepDetailsUpdated = <T extends ResourceType>(
  type: T,
  options: { enabled?: boolean; alwaysUpdate?: boolean } = {
    enabled: true,
    alwaysUpdate: false,
  }
) => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const hasActiveQueries =
    queryCache.findAll({
      queryKey: resourceKeys(type).detailsScope(),
      type: 'active',
    }).length > 0

  const enabled = options.enabled && (options.alwaysUpdate || hasActiveQueries)

  useResources(type, true, {
    refetchInterval: 1000,
    enabled,
  })

  return { enabled }
}
