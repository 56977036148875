import { useAppDispatch, useAppSelector } from '@/app'
import { DateChooser } from '../components/DateChooser'
import { useCalendarActions } from '@/features/calendar'
import { useCalendarSelectors } from '@/features/calendar'
import { useMediaQuery } from '@mui/material'

export const DateController = () => {
  const actions = useCalendarActions()
  const { selectDate } = useCalendarSelectors()
  const date = useAppSelector(selectDate)
  const dispatch = useAppDispatch()
  const isRoomForArrows = useMediaQuery('(min-width:320px)')
  const isRoomForToday = useMediaQuery('(min-width:380px)')

  return (
    <DateChooser
      date={date}
      onGotoDate={date => dispatch(actions.gotoDate(date))}
      onNextDate={() => dispatch(actions.increment())}
      onPrevDate={() => dispatch(actions.decrement())}
      onGotoToday={() => dispatch(actions.gotoToday())}
      hideArrows={!isRoomForArrows}
      useLogoAsToday={!isRoomForToday}
    />
  )
}
