import { PropertySchemas, parsedProp } from '@/api/parsing'
import {
  Employee,
  ResourceGroup,
  Schemas,
} from '@alexandrainst/plana-react-api'

export const getEmployeeResourceGroupsIds = (employee: Employee) => {
  return (
    parsedProp(employee, PropertySchemas.employee.resourceGroups)?.split(',') ??
    []
  )
}

export const getResourceGroupsAsEmployeeResourceGroups = (
  resourceGroups: ResourceGroup[] | undefined
) => {
  return resourceGroups?.map(rg => {
    return {
      id: rg.data.id ?? '',
      name: parsedProp(rg, PropertySchemas.resourceGroup.name) ?? '',
    }
  })
}

export const parseResourceGroups = (resourceGroups: unknown[] | undefined) => {
  return resourceGroups?.map(r => Schemas.ResourceGroup.parse(r))
}
