import {
  BackendServerState,
  Configuration,
  CoreState,
  FeatureNames,
  Features,
  GlobalState,
  LoggingState,
} from './coreSlice'

type GlobalStateV3 = {
  debug: boolean
  backendServer: BackendServerState
}

export type CoreStateV3 = {
  global: GlobalStateV3
}

export type CoreStateV4 = {
  global: GlobalState
  backendServer: BackendServerState
  config: {
    flags: Partial<Features<FeatureNames>>
  }
}

type CoreStateV6 = {
  global: GlobalState
  backendServer: BackendServerState
  config: Configuration
}

type CoreStateV7 = {
  global: GlobalState
  backendServer: BackendServerState
  config: Configuration
  logging: LoggingState
}

type CoreStateV8 = {
  global: GlobalState
  backendServer: BackendServerState
  config: Configuration
  logging: LoggingState
}

type CoreStateV9 = {
  global: GlobalState
  backendServer: BackendServerState
  config: Configuration
  logging: LoggingState
}

type CoreStateV10 = {
  global: GlobalState
  backendServer: BackendServerState
  config: Configuration
  logging: LoggingState
}

type CoreStateV11 = {
  global: GlobalState
  backendServer: BackendServerState
  config: Configuration
  logging: LoggingState
}

export const migrations = {
  4: (state: CoreStateV3): CoreStateV4 => {
    const { backendServer, ...global } = state.global
    return {
      ...state,
      global: { ...global },
      backendServer: { ...backendServer },
      config: { flags: { singleRouteMode: true, showDayRoutes: false } },
    }
  },
  6: (state: CoreState): CoreStateV6 => {
    return {
      ...state,
      config: { flags: { ...state.config.flags, useBurgerMenu: false } },
    }
  },
  7: (state: CoreStateV6): CoreStateV7 => {
    return {
      ...state,
      logging: {
        errorQueue: [],
        outputQueue: [],
      },
    }
  },
  8: (state: CoreStateV7): CoreStateV8 => {
    return {
      ...state,
      config: { flags: { ...state.config.flags, useZod: true } },
      logging: {
        errorQueue: [],
        outputQueue: [],
      },
    }
  },
  9: (state: CoreStateV8): CoreStateV9 => {
    return {
      ...state,
      config: { flags: { ...state.config.flags, hideInactiveVisits: true } },
    }
  },
  10: (state: CoreStateV9): CoreStateV10 => {
    return {
      ...state,
      config: { flags: { ...state.config.flags, useActualTime: false } },
    }
  },
  11: (state: CoreStateV10): CoreStateV11 => {
    return {
      ...state,
      config: { flags: { ...state.config.flags, automaticLogout: true } },
    }
  },
  15: (state: CoreStateV11): CoreState => {
    return {
      ...state,
      config: {
        flags: {
          ...state.config.flags,
          automaticLogout: true,
          showAllRoutes: true,
        },
      },
    }
  },
} as const
