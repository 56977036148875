import * as Sentry from '@sentry/react'
import { Button } from '@mui/material'
import { useAuthentication } from '@alexandrainst/plana-react-api'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
type Props = {
  onDone?: () => void
}
export const ReportErrorButton: FC<Props> = ({ onDone }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'core.reportErrorButton',
  })
  const {
    state: { userid },
  } = useAuthentication()

  const sendErrorReport = () => {
    console.info('Sending error report')
    const event = Sentry.captureEvent({
      message: `Error report from user ${userid}`,
      user: { id: userid },
    })
    onDone?.()
    Sentry.showReportDialog({
      eventId: event,
    })
  }

  return (
    <Button onClick={() => sendErrorReport()}>
      <Trans i18nKey="submit" t={t}>
        Submit error report
      </Trans>
    </Button>
  )
}
