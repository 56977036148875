import { useResource, useResourceBulk } from '@/api/hooks/useResource'
import { useCurrentUser } from '@/features/core'
import {
  getEmployeeResourceGroupsIds,
  getResourceGroupsAsEmployeeResourceGroups,
  parseResourceGroups,
} from '@/features/resource-groups/'

export const useResourceGroupsModel = () => {
  const { employeeId } = useCurrentUser()
  const { data: employee } = useResource('Employee', employeeId)

  const resourceGroupsIds =
    employee !== undefined ? getEmployeeResourceGroupsIds(employee) : undefined

  const resourceGroupsBulk = useResourceBulk(
    'ResourceGroup',
    resourceGroupsIds ?? [],
    {
      enabled: resourceGroupsIds !== undefined,
    }
  )

  const resourceGroups =
    resourceGroupsIds !== undefined &&
    resourceGroupsBulk.every(
      resourceGroup => resourceGroup.status === 'success'
    )
      ? parseResourceGroups(resourceGroupsBulk?.map(rg => rg.data))
      : undefined

  return {
    isLoading: resourceGroups === undefined,
    resourceGroups,
    employeeResourceGroups:
      getResourceGroupsAsEmployeeResourceGroups(resourceGroups),
  }
}
