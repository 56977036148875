import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import type { RootState } from '@/app'
import { stripTrailingSlash } from '@/common-util'
import { ApiClient, LogMessage } from '@alexandrainst/plana-react-api'

export type FeatureNames =
  | 'singleRouteMode'
  | 'showDayRoutes'
  | 'showAllRoutes'
  | 'useBurgerMenu'
  | 'useZod'
  | 'hideInactiveVisits'
  | 'useActualTime'
  | 'automaticLogout'

export type Features<T extends string> = {
  [E in T]: boolean
}
export type Configuration = {
  flags: Features<FeatureNames>
}
export type BackendServerState = {
  baseUrl: string
  systemId: string
  validating: 'idle' | 'pending'
  valid: boolean
}

export type GlobalState = {
  debug: boolean
}

export type LoggingState = {
  errorQueue: LogMessage[]
  outputQueue: LogMessage[]
}

export type CoreState = {
  global: GlobalState
  backendServer: BackendServerState
  config: Configuration
  logging: LoggingState
}

export const defaultFlags = {
  singleRouteMode: true,
  showDayRoutes: false,
  showAllRoutes: false,
  useBurgerMenu: false,
  useZod: true,
  hideInactiveVisits: true,
  useActualTime: false,
  automaticLogout: true,
} as const

const initialBackend: BackendServerState = {
  baseUrl: '',
  systemId: '',
  validating: 'idle',
  valid: false,
}

const initialConfiguration: Configuration = {
  flags: { ...defaultFlags, showAllRoutes: true },
}

const initialState: CoreState = {
  global: { debug: false },
  backendServer: initialBackend,
  config: initialConfiguration,
  logging: {
    errorQueue: [],
    outputQueue: [],
  },
}

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    enqueLogMessage: (state, action: PayloadAction<LogMessage>) => {
      state.logging.outputQueue = Array.from(
        new Set(
          [...state.logging.outputQueue, action.payload].map(it =>
            JSON.stringify(it)
          )
        )
      ).map(it => JSON.parse(it))
    },
    setDebug: (state, action: PayloadAction<boolean>) => {
      state.global.debug = action.payload
    },
    setFlag: (
      state,
      action: PayloadAction<{ feature: FeatureNames; value: boolean }>
    ) => {
      state.config.flags[action.payload.feature] = action.payload.value
    },
    resetBackendValidation: state => {
      state.backendServer.valid = false
      state.backendServer.baseUrl = ''
      state.backendServer.validating = 'idle'
      state.backendServer.systemId = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(setBackendServer.fulfilled, (state, action) => {
      state.backendServer.validating = 'idle'
      state.backendServer.baseUrl = action.payload.backendUrl
      state.backendServer.systemId = action.payload.systemId
      state.backendServer.valid = action.payload.valid
    })
    builder.addCase(setBackendServer.pending, state => {
      state.backendServer.validating = 'pending'
    })
    builder.addCase(setBackendServer.rejected, state => {
      state.backendServer.validating = 'idle'
    })
    builder.addCase(logToServer.fulfilled, (state, action) => {
      state.logging.errorQueue = action.payload.slice(
        -Math.min(50, action.payload.length)
      )
      state.logging.outputQueue = []
    })
  },
})

const isUsableUrl = (s: string) => {
  try {
    const url = new URL(s)
    return url.protocol === 'https:' || url.protocol === 'http:'
  } catch {
    return false
  }
}

export const setBackendServer = createAsyncThunk(
  'core/changeBackendStatus',
  async (backend: string) => {
    backend = backend.trim()
    const asUrl = isUsableUrl(backend)
      ? new URL(backend)
      : new URL(`https://plana.alexandra.dk/${encodeURIComponent(backend)}`)
    try {
      const statusUrl = stripTrailingSlash(asUrl.toString()) + '/resources'
      const result = await fetch(statusUrl)
      if (result.status === 200 || result.status === 403) {
        return { systemId: backend, backendUrl: asUrl.toString(), valid: true }
      } else {
        return { systemId: backend, backendUrl: asUrl.toString(), valid: false }
      }
    } catch {
      return { systemId: backend, backendUrl: asUrl.toString(), valid: false }
    }
  },
  {
    condition: (_backend, { getState }) =>
      selectBackend(getState() as RootState).validating === 'idle',
  }
)

export const logToServer = createAsyncThunk(
  'core/logToServer',
  async (
    {
      client,
      messages,
    }: {
      client: ApiClient
      messages: LogMessage[]
    },
    { dispatch, getState }
  ) => {
    const getCore = () => (getState() as { core: CoreState }).core
    const { enqueLogMessage } = coreSlice.actions
    const errorQueue = getCore().logging.errorQueue
    errorQueue
      .concat(messages)
      .forEach(message => dispatch(enqueLogMessage(message)))
    const messagesToSend = getCore().logging.outputQueue
    try {
      await client.sendLog(messagesToSend)
      return []
    } catch {
      return messagesToSend
    }
  }
)

export const actions = coreSlice.actions
const selectCore = (state: RootState) => state.core
const selectConfig = createSelector(selectCore, c => c.config)
const selectFlags = createSelector(selectConfig, c => c.flags)
const selectFlag = (flagName: FeatureNames) =>
  createSelector(selectFlags, f => f[flagName])
const selectGlobal = createSelector(selectCore, c => c.global)
const selectDebug = createSelector(selectGlobal, g => g.debug)
const selectBackend = createSelector(selectCore, c => c.backendServer)

export const selectors = {
  selectCore,
  selectConfig,
  selectFlags,
  selectFlag,
  selectGlobal,
  selectDebug,
  selectBackend,
}

export default coreSlice.reducer
