import { ReactNode, useCallback } from 'react'
import { ServiceVisitEditorProps } from './ServiceVisitEditor'
import { useActualTime } from './actualTimeEditorHook'
import { useServiceVisitData } from '@/features/calendar/components/ServiceVisit/hooks'
import { useResource, useServerLog, useUpdateResource } from '@/api'
import { VisitState } from '@/common-util/types'
import { useAppSelector } from '@/app'
import { PropertySchemas } from '@/api/parsing'
import { setParsedProp } from '@/api/parsing/properties'
import { selectors as coreSelectors, useCurrentUser } from '@/features/core'
import { produce } from 'immer'
import { ServiceVisitEditorContext } from './ServiceVisitEditorContext'

export type ServiceVisitEditorProviderProps = ServiceVisitEditorProps & {
  children: ReactNode
}
export const ServiceVisitEditorProvider = ({
  visitId,
  debug = false,
  children,
}: ServiceVisitEditorProviderProps) => {
  const { info } = useServerLog()
  const { data: serviceVisit } = useResource('ServiceVisit', visitId)
  const serviceVisitData = useServiceVisitData(serviceVisit)
  const actualTimeData = useActualTime(serviceVisitData.visit)

  const { useActualTime: actualTimeFeatureActivated } = useAppSelector(
    coreSelectors.selectFlags
  )
  const { employeeId } = useCurrentUser()
  const { mutate, status: mutationStatus } = useUpdateResource('ServiceVisit')

  const setState = useCallback(
    (newState: VisitState, cancellationId?: string) => {
      if (serviceVisit !== undefined) {
        if (newState === 'Completed' && actualTimeFeatureActivated) {
          if (actualTimeData.handleComplete()) {
            return
          }
        }

        const newServiceVisit =
          cancellationId !== undefined
            ? setParsedProp(
                serviceVisit,
                PropertySchemas.serviceVisit.cancellationId,
                cancellationId
              )
            : serviceVisit

        mutate(
          produce(
            setParsedProp(
              newServiceVisit,
              PropertySchemas.serviceVisit.visitState,
              newState
            ),
            draft => {
              draft.data.origin = 'Calendar'
              if (employeeId && employeeId !== '') {
                draft.data.editor = employeeId
              }
            }
          )
        )
        info(`Setting state of visit ${newServiceVisit.data.id} to ${newState}`)
      }
    },
    [
      actualTimeData,
      actualTimeFeatureActivated,
      employeeId,
      info,
      mutate,
      serviceVisit,
    ]
  )

  return (
    <ServiceVisitEditorContext.Provider
      value={{
        serviceVisitData,
        actualTimeData,
        setState,
        mutationStatus,
        debug,
      }}
    >
      {children}
    </ServiceVisitEditorContext.Provider>
  )
}
