import { useResources } from '@/api'
import { useAppDispatch, useAppSelector } from '@/app'
import { parsedProp, PropertySchemas } from '@/api/parsing'
import { useCalendarActions, useCalendarSelectors } from '@/features/calendar'
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'

type RouteSelectorControllerProps = {
  disabled?: boolean
}

export const RouteSelectorController = ({
  disabled,
}: RouteSelectorControllerProps) => {
  const { t } = useTranslation()
  const actions = useCalendarActions()
  const { selectSelectedRoutes, selectSelectedGroup } = useCalendarSelectors()
  const selectedGroup = useAppSelector(selectSelectedGroup)
  const selectedRoutes = useAppSelector(selectSelectedRoutes)
  const dispatch = useAppDispatch()
  const { data: routes } = useResources('Route')

  const handleSelectRoutes = React.useCallback(
    (e: SelectChangeEvent<typeof selectedRoutes>) => {
      const {
        target: { value },
      } = e
      if (value.includes('all')) {
        dispatch(actions.selectRoutes([]))
      } else {
        dispatch(
          actions.selectRoutes(typeof value === 'string' ? [value] : value)
        )
      }
    },
    [dispatch, actions]
  )

  const routesInSelectedGroup =
    routes?.filter(r =>
      parsedProp(r, PropertySchemas.route.groups)?.includes(selectedGroup)
    ) ?? []

  const actualSelectedRoutes = routesInSelectedGroup.filter(it =>
    selectedRoutes.includes(it.data.id)
  )

  return (
    <FormControl fullWidth disabled={disabled} size="small">
      <InputLabel id="select-routes-label">
        {t('planning.chooseroutes')}
      </InputLabel>
      <Select
        size="small"
        labelId="select-routes-label"
        name="select-routes"
        id="select-routes"
        value={selectedRoutes}
        multiple
        onChange={handleSelectRoutes}
        input={<OutlinedInput label={t('planning.chooseroutes')} />}
        renderValue={selected =>
          selected.length === 0
            ? 'Showing all routes'
            : actualSelectedRoutes.length === 1
              ? (parsedProp(
                  actualSelectedRoutes[0],
                  PropertySchemas.route.name
                ) ?? '')
              : t('planning.showingRoutes', {
                  count: actualSelectedRoutes?.length,
                })
        }
      >
        <MenuItem value="all">{t('common.all')}</MenuItem>
        {[...(routesInSelectedGroup ?? [])]
          .sort((a, b) =>
            (parsedProp(a, PropertySchemas.route.name) ?? '').localeCompare(
              parsedProp(b, PropertySchemas.route.name) ?? ''
            )
          )
          .map((route, idx) => (
            <MenuItem key={idx} value={route.data.id}>
              {parsedProp(route, PropertySchemas.route.name)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
