import { useAppDispatch, useAppSelector } from '@/app'
import { FormControlLabel, Switch } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  actions,
  FeatureNames,
  selectors as coreSelectors,
} from '../core/coreSlice'
import { useOpenFeatureClient } from '@/features/feature-flags'
import { ClientProviderStatus } from '@openfeature/core'

interface Props {
  feature: FeatureNames
  defaultEnabled?: boolean
}

export const FeatureToggle = ({ feature }: Props) => {
  const { t } = useTranslation()
  const flag = useAppSelector(coreSelectors.selectFlag(feature))
  const dispatch = useAppDispatch()
  const toggleFlag = () => dispatch(actions.setFlag({ feature, value: !flag }))
  const client = useOpenFeatureClient()
  if (
    client.providerStatus === ClientProviderStatus.READY &&
    client.getBooleanValue(feature, true)
  ) {
    return (
      <FormControlLabel
        control={
          <Switch
            color="warning"
            checked={flag}
            onChange={toggleFlag}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={t(`feature.${feature}`)}
        labelPlacement="end"
      />
    )
  }
  return null
}
