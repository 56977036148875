import Grid from '@mui/material/Grid2'
import { useAppDispatch, useAppSelector } from '@/app'
import { CalendarProvider, useCalendarSelectors } from '@/features/calendar'
import { ZoomController } from '@/features/calendar/controllers/ZoomController'
import { actions, selectors } from '@/features/route-browser'
import {
  DateController,
  RouteGroupSelectorController,
  RouteSelectorController,
} from '@/features/calendar/controllers'
import { ServiceVisitEditorDialog } from '@/incubator/ServiceVisitEditor/ServiceVisitEditor'
import {
  GlobalLoadingIndicator,
  selectors as coreSelectors,
} from '@/features/core'
import {
  Box,
  FormControlLabel,
  LinearProgress,
  Paper,
  Stack,
  Switch,
} from '@mui/material'
import { useStyles } from './RouteBrowserView.style'
import { RouteList } from '@/features/calendar/components'
import { useAutoscroll } from '@/features/calendar/util'
import { useResources, useResourcesBySource } from '@/api'
import { parsedProp, PropertySchemas } from '@/api/parsing'
import { useTranslation } from 'react-i18next'
import { useAuthentication } from '@alexandrainst/plana-react-api'
import { useResourceBulk } from '@/api/hooks/useResource'

const RouteBrowserMainViewContent = () => {
  const { classes } = useStyles()
  const debug = useAppSelector(coreSelectors.selectDebug)
  const {
    calendar: {
      date,
      scale,
      gutterInterval,
      legacyGrouping,
      visitEditorOpen,
      selectedVisit,
    },
    state: { isRouteSelctionOpen, useFilter },
  } = useAppSelector(selectors.selectRouteBrowser)
  const { t } = useTranslation('translation', { keyPrefix: 'routeBrowser' })
  const { selectSelectedGroup, selectSelectedRoutes } = useCalendarSelectors()
  const selectedGroup = useAppSelector(selectSelectedGroup)
  const selectedRoutes = useAppSelector(selectSelectedRoutes)
  const dispatch = useAppDispatch()

  const {
    state: { externalSystemId: me },
  } = useAuthentication()

  const { data: dayRouteHeaders } = useResourcesBySource(
    'DayRouteHeader',
    'Employee',
    me,
    date,
    date
  )

  const employeesRoutes = useResourceBulk(
    'Route',
    dayRouteHeaders?.map(it => it.data.route) ?? []
  )

  const employeeRouteGroups = Array.from(
    new Set(
      employeesRoutes
        .map(it => parsedProp(it.data, PropertySchemas.route.groups))
        .flat()
    )
  )

  const { isLoading, data: routes } = useResources('Route')

  const routesInSelectedGroup =
    routes?.filter(r =>
      useFilter
        ? parsedProp(r, PropertySchemas.route.groups)?.includes(selectedGroup)
        : employeeRouteGroups.some(group =>
            parsedProp(r, PropertySchemas.route.groups)?.includes(group)
          )
    ) ?? []

  const actualSelectedRoutes = routesInSelectedGroup.filter(
    it =>
      !useFilter ||
      selectedRoutes.includes(it.data.id) ||
      selectedRoutes.length === 0
  )

  const { ref: scrollContainerRef } = useAutoscroll(
    date,
    [
      {
        position: Math.max(
          0,
          new Date().getHours() * 60 + new Date().getMinutes() - 60
        ),
      },
    ],
    scale
  )

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      sx={{ background: '#1c78d3' }}
    >
      <ServiceVisitEditorDialog
        open={visitEditorOpen}
        visitId={selectedVisit}
        onClose={() => dispatch(actions.closeVisitEditor())}
        debug={debug}
      />
      <Grid size={{ xs: 'auto', md: 'auto' }}></Grid>
      <Grid size={{ xs: 8, md: 10 }}>
        <DateController />
      </Grid>
      <Grid size={{ xs: 'grow' }}>
        <Box pr={2} sx={{ textAlign: 'right' }}>
          <GlobalLoadingIndicator />
        </Box>
      </Grid>
      <Grid size={{ md: 12, xs: 12 }}>
        <LinearProgress
          variant={isLoading ? 'indeterminate' : 'determinate'}
          value={100}
        />
        <Paper elevation={3} className={classes.container}>
          {isRouteSelctionOpen ? (
            <Paper elevation={3} className={classes.routeSelectionContainer}>
              <Stack direction="row" spacing={2}>
                <RouteGroupSelectorController disabled={!useFilter} />
                <RouteSelectorController disabled={!useFilter} />
              </Stack>

              <FormControlLabel
                control={
                  <Switch
                    color="warning"
                    checked={useFilter}
                    onChange={() => dispatch(actions.toggleUseFilter())}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={t('useFilter')}
                labelPlacement="end"
              />
            </Paper>
          ) : (
            <>
              <ZoomController />
              <div ref={scrollContainerRef} className={classes.routeContainer}>
                <RouteList
                  ids={
                    [...(actualSelectedRoutes ?? [])]
                      ?.sort((a, b) =>
                        (
                          parsedProp(a, PropertySchemas.route.name) ?? ''
                        ).localeCompare(
                          parsedProp(b, PropertySchemas.route.name) ?? ''
                        )
                      )
                      .map(r => r.data.id) ?? []
                  }
                  date={date}
                  scale={scale}
                  interval={gutterInterval}
                  legacyLayout={legacyGrouping}
                />
              </div>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export const RouteBrowserMainView = () => {
  return (
    <CalendarProvider
      calendarSelector={selectors.selectCalendar}
      calendarActions={actions}
    >
      <RouteBrowserMainViewContent />
    </CalendarProvider>
  )
}
