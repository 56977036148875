import { ResourceType } from '@alexandrainst/plana-react-api'

export const resourceKeys = <T extends ResourceType>(type: T) => ({
  all: [{ entity: type }] as const,
  lists: (resourceGroupFilter: string[]) =>
    [
      ...resourceKeys(type).all,
      { scope: 'list' },
      { resourceGroupFilter },
    ] as const,
  bySourceScope: () =>
    [...resourceKeys(type).all, { scope: 'bySource' }] as const,
  bySourceListsType: (sourceType: ResourceType) =>
    [
      ...resourceKeys(type).bySourceScope(),
      {
        sourceType,
      },
    ] as const,
  bySource: (
    sourceType: ResourceType,
    sourceId: string,
    from: string,
    to: string,
    resourceGroupFilter: string[]
  ) =>
    [
      ...resourceKeys(type).bySourceScope(),
      {
        sourceType,
        sourceId,
        from,
        to,
        resourceGroupFilter,
      },
    ] as const,
  detailsScope: () =>
    [...resourceKeys(type).all, { scope: 'details' }] as const,
  details: (id: string) =>
    [...resourceKeys(type).detailsScope(), { id }] as const,
})
