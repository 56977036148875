import { RootState } from '@/app'
import {
  CalendarState,
  createCalendarSlice,
} from '@/features/calendar/calendarViewSlice'
import { createContext, useContext } from 'react'

export type CalendarContextType = {
  selectCalendar: (s: RootState) => CalendarState
  actions: ReturnType<typeof createCalendarSlice>['actions']
  selectors: {
    selectSelectedVisit: (s: RootState) => string
    selectIsSelected: (s: RootState, id: string) => boolean
    selectSelectedGroup: (s: RootState) => string
    selectDate: (s: RootState) => string
    selectScale: (s: RootState) => number
    selectSelectedRoutes: (s: RootState) => string[]
    selectGutterInterval: (s: RootState) => number
    selectLegacyGrouping: (s: RootState) => boolean
    selectIsVisitEditorOpen: (s: RootState) => boolean
    selectMoveVisit: (s: RootState) => CalendarState['moveVisit']
    selectMoveVisitForRoute: (
      s: RootState,
      routeId: string
    ) => CalendarState['moveVisit'] | undefined
  }
}

export const CalendarContext = createContext<CalendarContextType | undefined>(
  undefined
)

export type CalendarProps = {
  calendarSelector: (s: RootState) => CalendarState
  calendarActions: ReturnType<typeof createCalendarSlice>['actions']
}

const useCalendar = () => {
  const ctx = useContext(CalendarContext)
  if (ctx === undefined) {
    throw new Error('useCalendar must be called inside a Calendar provider')
  }
  return ctx
}

export const useCalendarActions = () => useCalendar().actions
export const useCalendarSelectors = () => {
  const { selectors } = useCalendar()

  return {
    ...selectors,
  }
}
