import { ShellState } from './shellSlice'

export interface ShellStateV4 {
  focus: 'planning' | 'dayRoutes' | 'singleRoute' | 'config'
}

export interface ShellStateV12 {
  focus: 'planning' | 'dayRoutes' | 'singleRoute' | 'config' | 'splash'
  firstLaunchToday: boolean
  timers: {
    timeSinceLastLaunch: number
    lastLaunched: string
  }
}

export interface ShellStateV14 {
  focus: 'planning' | 'dayRoutes' | 'singleRoute' | 'config' | 'splash'
  previousFocus: 'planning' | 'dayRoutes' | 'singleRoute' | 'config' | 'splash'
  firstLaunchToday: boolean
  timers: {
    timeSinceLastLaunch: number
    lastLaunched: string
  }
}

export const migrations = {
  5: (state: ShellStateV4): ShellStateV12 => {
    return {
      ...state,
      firstLaunchToday: true,
      timers: {
        timeSinceLastLaunch: 0,
        lastLaunched: new Date(0).toUTCString(),
      },
    }
  },
  13: (state: ShellStateV12): ShellStateV14 => {
    return {
      ...state,
      previousFocus: state.focus,
    }
  },
  15: (state: ShellStateV14): ShellState => {
    const { focus, previousFocus, ...rest } = state
    return {
      ...rest,
      focus: focus === 'dayRoutes' ? 'routeBrowser' : focus,
      previousFocus:
        previousFocus === 'dayRoutes' ? 'routeBrowser' : previousFocus,
    }
  },
}
